import React from "react"
import {useThunk} from "../../../../../../api/methods/useThunk";
import {dealReducerThunk} from "../../../../../../store/reducers/deal";
import {usePing} from "../../../../../../api/methods/usePing";
import {useLocation} from "react-router-dom";
import {Support} from "../../../../../../supports";
import {LoadingStage} from "../../../../ui/LoadingStage";

export const GetStatusAfter = () => {

    const {pathname} = useLocation()
    const {getters, thunk, dispatch} = useThunk(dealReducerThunk, {
        initialLoading: true
    })

    usePing(() => {
        if (getters.loading) {
            dispatch(thunk.getStatusWithOutRedirect({
                deal_id: new Support().getNumber(pathname)
            }))
        }
    }, 3000)

    return <LoadingStage/>
}