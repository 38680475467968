import React from 'react';
import {Title} from "../../../../ui/Title";
import './style.css'
import {useTranslation} from "react-i18next";

export const FAQ = () => {

    const {t} = useTranslation()

    const faqData = [
        {
            color: '#1A4AB9',
            title: t('FAQ.item1.title'),
            text: t('FAQ.item1.text'),
        },
        {
            color: '#00D5E2',
            title: t('FAQ.item2.title'),
            text: t('FAQ.item2.text'),
        },
        {
            color: '#FF8E8E',
            title: t('FAQ.item3.title'),
            text: t('FAQ.item3.text'),
        }
    ]

    return (
        <div id='sec-2' className='FAQ'>
            <Title>
                {t('FAQ.header')}
            </Title>
            <div className='FAQ__body Grid'>
                {faqData.map(item => (
                    <div className='FAQ__item'>
                        <div
                            style={{backgroundColor: item.color}}
                            className='FAQ__item__icon'
                        >
                            ?
                        </div>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}