import {Button} from "../../../../../../../../ui/Button";
import React, {useEffect, useState} from "react";
import './style.css'
import {Card} from "../../../../../../../../ui/Card";
import {useSelector} from "react-redux";
import {CardValueType} from "../../../../../../../../ui/Card/types";
import {dealReducerSelectors, dealReducerThunk} from "../../../../../../../../../store/reducers/deal";
import {methods} from "../../../../../../../../../api/methods";
import {useTranslation} from "react-i18next";

export const SendMoneyCard = () => {
    const {setters, getters, dispatch, thunk} = methods.useThunk(dealReducerThunk)
    const {id} = useSelector(dealReducerSelectors.getDeal)
    const [card, setCard] = useState<CardValueType>({
        number: '',
        date: '',
        cvv: '',
    })
    const {t} = useTranslation()


    const fieldsErrors = methods.useErrors({
        Fields: {
            card_number: '',
            exp_year: '',
            exp_month: '',
            cvv: '',
        },
        Errors: getters.errors
    })


    const sendData = () => {
        dispatch(thunk.startDeal({
            // @ts-ignore
            card_number: Number(card.number.replaceAll(' ', '')),
            deal_id: Number(id),
            card_holder: null,
            cvv: card.cvv,
            exp_month: Number(card.date.slice(0, 2)),
            exp_year: Number('20' + card.date.slice(3))
        }, 'enterData'))
    }

    /* effects */
    useEffect(() => {
        window.scrollTo(
            0, (document.querySelector('.Main__SendAndGet .Title') as HTMLDivElement)?.offsetTop
        )
    }, [])

    return (
        <div className='CardStage'>
            <h4>
                {t('SendAndGet.sendMoney.card.title')}
            </h4>
            <Card error={fieldsErrors} onChangeCardValue={setCard}/>

            <button
                className='CardStage__back-btn'
                onClick={() => setters.history.push('/')}
            >
                {t('SendAndGet.sendMoney.card.back', 'Вернуться назад')}
            </button>

            <Button
                onClick={sendData}
            >
                {t('SendAndGet.sendMoney.card.button')}
            </Button>
        </div>
    )
}