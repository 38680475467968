import React, {FC} from 'react'
import './style.css'
import {useTranslation} from "react-i18next";
import logo from './logo.png'

export const Footer: FC = () => {

    const {t} = useTranslation()

    return (
        <footer className='Footer Grid'>
           <div className='Footer__content'>
               <h4>KIPARIS DMCC</h4>
               <p>
                   Unit no. 3O-01-BA322, Jewellery & Gemplex 3
                   Plot No: DMCC – PH2- J&GPlexS
                   Jewellery & Gemplex, Jumeirah Lakes Towers
                   Dubai, United Arab Emirates
               </p>
               <a href='mailto:info@kiparisdmcc.ae'>info@kiparisdmcc.ae</a>
           </div>
           <img className='Footer__logo' src={logo}/>
        </footer>
    )
}
