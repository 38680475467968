import React, {useEffect} from "react";
import {Route, useLocation} from "react-router-dom";
import {routes} from "../../../../routes";
import {Header} from "../../Header";
import {SendAndGet} from "./Sections/SendAndGet";
import {Footer} from "../../Footer";
import {RedirectForm} from "../../../RedirectForm";
import './style.css'
import {useSelector} from "react-redux";
import {dealReducerSelectors} from "../../../../store/reducers/deal";
import {FirstSection} from "./Sections/FirstSection";
import {Benefits} from "./Sections/Benefits";
import {FAQ} from "./Sections/FAQ";
import {Feedback} from "./Sections/Feedback";


export function Main() {

    const redirect = useSelector(dealReducerSelectors.getRedirect)
    const {pathname} = useLocation()

    useEffect(() => {
        if (pathname.includes('operation')) {
            document.getElementById('Section-3')?.scrollIntoView()
        }
    }, [pathname])

    return (
        <div className='Main'>
            <Route path={routes.landing.root}>
                {redirect !== null && (
                    <RedirectForm
                        action={redirect.url}
                        method={redirect.method}
                        params={redirect.params}
                    />
                )}
                <Header/>
                <FirstSection/>
                <Benefits/>
                <SendAndGet/>
                <FAQ/>
                <Feedback/>
                <Footer/>
            </Route>
        </div>
    )
}
