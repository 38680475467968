import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../../../../ui/Button";
import {StagesCloseButton} from "../../../../../../../ui/StagesCloseButton";
import {useHistory} from "react-router-dom";
import {RejectStatus} from "../../../../../../../ui/Statuses/Reject";


export const SendMoneyEnterDataReject = () => {


    return <RejectStatus/>
}