import React from 'react'
import {useSelector} from "react-redux";
import {methods} from "../../../../../../../../../api/methods";
import {useThunk} from "../../../../../../../../../api/methods/useThunk";
import {dealReducerSelectors, dealReducerThunk} from "../../../../../../../../../store/reducers/deal";
import {LoadingStage} from "../../../../../../../ui/LoadingStage";

export const SendMoneyEnterDataLoading = () => {
    const {id} = useSelector(dealReducerSelectors.getDeal)
    const {dispatch, thunk} = useThunk(dealReducerThunk)

    methods.usePing(() => {
        dispatch(thunk.getStatus({
            deal_id: Number(id)
        }))
    }, 3000)

    return <LoadingStage/>
}