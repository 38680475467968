import React, {useState} from "react";
import {SelectListElementsType} from "../Select/types";

/* @types */
type PropsType = {
    initialValue?: string
    initialSelected?: SelectListElementsType | null
    listElements?: SelectListElementsType[]
    callback?(value: string | null | SelectListElementsType): void | null
}

type ReturnedValueType = {
    value: string
    selected: SelectListElementsType | null
    listElements: SelectListElementsType[]
    onSelected(element: SelectListElementsType): void
    onChange(event: React.ChangeEvent<HTMLInputElement>): void
    localList: SelectListElementsType[],
    setLocalList: (value: SelectListElementsType[]) => void,
    clear: () => void
}

export type UseSelectReturnedValueType = ReturnedValueType

export const useSelect = (props: PropsType = {}): ReturnedValueType => {

    /* props */
    const {
        initialValue = '',
        initialSelected = null,
        listElements = [],
        callback = () => null
    } = props;

    /* state */
    const [value, setValue] = useState<string>(initialValue)
    const [selected, setSelected] = useState<SelectListElementsType | null>(initialSelected)
    const [localList, setLocalList] = useState<Array<SelectListElementsType>>(listElements)


    /* methods */
    const clear = () => {
        setValue('')
    }
    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
        callback(event.target.value)
        setLocalList(listElements.filter(item =>
            item.text
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
            )
        )
    }

    const handleSelectedEvent = (element: SelectListElementsType) => {
        setValue(element.text)
        setSelected(element)
        callback(element)
    }

    return {
        value,
        selected,
        listElements,
        onChange: handleChangeEvent,
        onSelected: handleSelectedEvent,
        localList,
        setLocalList,
        clear
    }
}