import {useEffect, useState} from "react";

type useCommissionType = {
    amount: string,
    minimal: number,
    commission: number,
}

export function useCommission({amount, minimal, commission}: useCommissionType): string {

    const [calculations, setCalculations] = useState<string>('0.0');

    function calc(): number {

        const result = Number(amount) * commission;

        return result > minimal
            ? result
            : minimal
    }

    useEffect(() => {
        if (amount) {
            setCalculations(calc().toFixed(2))
        } else {
            setCalculations('0.0')
        }
    }, [amount])

    return calculations
}
