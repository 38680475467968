import React, {FC, useState} from 'react';
import {CardValueType, PropsType} from './types'
import cn from 'classnames'
import InputMask from 'react-input-mask'
import './Card.css';
import {Trans, useTranslation} from "react-i18next";

export const Card: FC<PropsType> = (props) => {

    const mock = {
        number: '',
        date: '',
        cvv: ''
    }

    const {
        className = '',
        error,
        initial = mock,
        loading,
        required,
        onChangeCardValue,
        ...other
    } = props;
    const {t} = useTranslation()

    const [cardValue, setCardValue] = useState<CardValueType>({
        number: initial.number,
        date: initial.date,
        cvv: initial.cvv,
    })

    /* methods */
    function handleChangeForm(event: React.ChangeEvent<HTMLFormElement>) {

        const {value, name} = event.target;

        setCardValue((pre: CardValueType) => {
            let actual = {...pre, [name]: value}
            if (onChangeCardValue) {
                onChangeCardValue(actual)
            }
            return actual
        })
    }


    return (
        <div
            className={cn({
                [className]: true,
                'Card': true,
                'Card--loading': loading
            })} {...other}
        >
            <form className="Card__form Pd(v22) Pd(t36) Pd(b46)" onChange={handleChangeForm}>
                <div
                    className={cn({
                        'Card__form__input': true,
                        'Card__form__input--error': !!error?.card_number,
                        'Mg(b20)': true
                    })}
                >
                    <InputMask
                        name="number"
                        type="tel"
                        placeholder='XXXX XXXX XXXX XXXX'
                        mask='9999 9999 9999 9999'
                        maskChar={null}
                        value={cardValue.number}
                        disabled={loading}
                        required={required}
                    />
                    {error?.card_number && <span>{error.card_number}</span>}
                </div>
                <div
                    className={cn({
                        'Card__form__input': true,
                        'Card__form__input--error': !!(error?.exp_month || error?.exp_year),
                        'Mg(r24)': true
                    })}
                >
                    <InputMask
                        name="date"
                        type="tel"
                        placeholder='MM/YY'
                        value={cardValue.date}
                        mask='99/99'
                        maskChar={null}
                        disabled={loading}
                        required={required}
                    />
                    {(error?.exp_month || error?.exp_year) && <span>{error.exp_month || error.exp_year}</span>}
                </div>
                <div
                    className={cn({
                        'Card__form__input': true,
                        'Card__form__input--error': !!error?.cvv,
                    })}
                >
                    <InputMask
                        name="cvv"
                        type="password"
                        placeholder='CVV'
                        mask='999'
                        maskChar={null}
                        value={cardValue.cvv}
                        disabled={loading}
                        required={required}
                    />
                    {/*<div className='Card__hint'>*/}
                    {/*    <div>?</div>*/}
                    {/*    <div>*/}
                    {/*        <Trans i18nKey='Card.hint'>*/}
                    {/*            <strong>CVC или CVV</strong><br/>*/}
                    {/*            это цифровой код, расположенный на обратной*/}
                    {/*            стороне карты, которым подтверждается подлинность карты*/}
                    {/*        </Trans>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {error?.cvv && <span>{error.cvv}</span>}
                </div>
            </form>
        </div>
    )
}
