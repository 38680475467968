import React , {FC} from 'react'
import './TextArea.css'
import {PropsType} from './types'

export const TextArea:FC<PropsType> = (props) => {


    return (
        <textarea className='TextArea' {...props}>

        </textarea>
    )
}
