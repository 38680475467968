export const dealRoutes = {
    start: {
        root: '/deal/start',
        sendMoney: '/deal/start/sendMoney/enterData',
        getMoney: '/deal/start/getMoney',
        getCode: '/deal/start/sendMoney/getCode'
    },

    enterCard: {
        root: '/deal/cardData',
        sendMoney: {
            enterData: '/deal/cardData/sendMoney/enterData',
            getCode: '/deal/cardData/sendMoney/getCode'
        }
    },

    loading: {
        root: '/deal/loading',
        sendMoney: {
            enterData: '/deal/loading/sendMoney/enterData',
            getCode: '/deal/loading/sendMoney/getCode'
        },
        getMoney: '/deal/loading/getMoney'
    },

    success: {
        root: '/deal/success',
        sendMoney: {
            enterData: '/deal/success/sendMoney/enterData',
            getCode: '/deal/success/sendMoney/getCode'
        }
    },

    reject: {
        root: 'deal/reject',
        sendMoney: {
            enterData: '/deal/reject/sendMoney/enterData',
            getCode: '/deal/reject/sendMoney/getCode'
        }
    },
}