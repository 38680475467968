import React, {FC, useState} from "react";
import './style.css'
import logo from './images/logo.svg'
import {useTranslation} from "react-i18next";
import {Button} from "../../ui/Button";
import ru from './images/ru.svg'
import en from './images/en.svg'
import {SwitchWithText} from "../../ui/SwitchWithText";
import {GrClose, HiMenuAlt3} from 'react-icons/all'
import cn from 'classnames'
import {dealRoutes} from "../pages/root/Sections/SendAndGet/Stages";
import { useHistory } from "react-router-dom";

export const Header: FC = () => {

    const {t, i18n} = useTranslation()
    const [isNavOpened, setIsNavOpened] = useState(false)
    const [lang, setLang] = useState('Ru')
    const history = useHistory()

    /* methods */
    const toggleNav = () => {
        setIsNavOpened(!isNavOpened)
    }

    function handleChangeLang() {
        if (i18n.language == 'ru') {
            i18n.changeLanguage('en').finally()
        } else {
            i18n.changeLanguage('ru').finally()
        }
    }

    const scrollTo = (id: string) => {
        let top = document.getElementById(id)!.offsetTop;
        window.scrollTo(0, top - 100);
    }

    const sendMoney = () => {
        let top = document.getElementById('sec-1')!.offsetTop;
        window.scrollTo(0, top-100);
        history.push(dealRoutes.start.sendMoney)
    }

    /* view */
    const displayLandSwitcher = () => {
        if (i18n.language == 'ru') {
            return (
                <>
                    <img src={ru}/>
                    Ru
                </>
            )
        } else {
            return (
                <>
                    <img src={en}/>
                    En
                </>
            )
        }
    }

    return (
        <div className='Header'>
            <img src={logo}/>
            <HiMenuAlt3
                className='Header__open-btn'
                onClick={toggleNav}
            />
            <div
                className={cn({
                    'Header__nav': true,
                    'Header__nav--opened': isNavOpened
                })}
            >
                <SwitchWithText
                    className='Header__lang-switcher--mobile'
                    value={lang}
                    values={[
                        {
                            value: 'Ru',
                            text: 'Ru'
                        },
                        {
                            value: 'Eng',
                            text: 'Eng'
                        }
                    ]}
                    onChange={(v) => {
                        handleChangeLang()
                        setLang(v)
                    }}
                />

                <GrClose
                    className='Header__close-btn'
                    onClick={toggleNav}
                />
                <ul>
                    <li>
                        <a onClick={() => scrollTo('sec-3')}>
                            {t('Footer.link6')}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => scrollTo('sec-1')}>
                            {t('Footer.link3')}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => scrollTo('sec-2')}>
                            {t('Footer.link5')}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => scrollTo('sec-1')}>
                            {t('Footer.link4')}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => scrollTo('sec-4')}>
                            {t('Footer.link7', 'Преимущества')}
                        </a>
                    </li>
                    <li
                        onClick={handleChangeLang}
                        className='Header__land-switcher'
                    >
                        {displayLandSwitcher()}
                    </li>
                </ul>
                <Button onClick={sendMoney}>
                    {t('Header.sendMoney')}
                </Button>
            </div>
        </div>
    )
}
