import React, {FC, useState} from 'react'
import './style.css'
import {Title} from "../../../../ui/Title";
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import {useTranslation} from "react-i18next";




export const Benefits:FC = () => {
    const {t} = useTranslation()

    const listData = [
        {
            img: img1,
            title: t('Benefits.item1.title'),
            color: '#1A4AB9',
            text: t('Benefits.item1.text')
        },
        {
            img: img2,
            title: t('Benefits.item2.title'),
            color: '#27BFFF',
            text: t('Benefits.item2.text'),
        },
        {
            img: img3,
            title: t('Benefits.item3.title'),
            color: '#FF3D3D',
            text: t('Benefits.item3.text'),
        }
    ]

    return (
        <section id='sec-4' className='Benefits'>
            <Title>
                {t('Benefits.header')}
            </Title>
            <div className='Benefits__list'>
                {listData.map(item => (
                    <div className='Benefits__list-item'>
                        <img src={item.img} alt=""/>
                        <h3 style={{color: item.color}}>
                            {item.title}
                        </h3>
                        <p>{item.text}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}