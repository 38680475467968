import React, {FC, useEffect, useState} from 'react'
import './style.css'
import cn from 'classnames'

type PropsType = {
    value: string
    values: Array<{text: string, value: string}>,
    onChange: (value: string) => void,
    className?: string,
    variant?: 'white' | 'purple'
}
export const SwitchWithText:FC<PropsType> = (props) => {
    const {
        values,
        onChange,
        className,
        value,
        variant = 'white'
    } = props

    const changeValue = () => {
        if (value == values[0].value) {
            onChange(values[1].value)
        } else {
            onChange(values[0].value)
        }
    }

    return (
        <div className={cn('SwitchWithText', {
            'SwitchWithText--left-active': value==values[0].value,
            'SwitchWithText--right-active': value==values[1].value,
            [`SwitchWithText--${variant}`]: true,
            [`${className}`]: true
        })} onClick={changeValue}>
            <div className='SwitchWithText__text'>{values[0].text}</div>
            <div className='SwitchWithText__text'>{values[1].text}</div>
            <div className='SwitchWithText__bg-active'/>
        </div>
    )
}