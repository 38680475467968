import React, {FC} from 'react'
import './style.css'
import img1 from './images/desc/1.png'
import img2 from './images/desc/2.png'
import {Button} from "../../../../../ui/Button";
import { useHistory } from 'react-router-dom';
import {dealRoutes} from "../SendAndGet/Stages";
import {Trans, useTranslation} from "react-i18next";

export const FirstSection:FC = () => {

    const history = useHistory()
    const {t} = useTranslation()

    const sendMoney = () => {
        let top = document.getElementById('sec-1')!.offsetTop;
        window.scrollTo(0, top-100);
        history.push(dealRoutes.start.sendMoney)
    }

    const getMoney = () => {
        let top = document.getElementById('sec-1')!.offsetTop;
        window.scrollTo(0, top-100);
        history.push(dealRoutes.start.getMoney)
    }

    return (
        <section className='Main__FirstSection Grid'>
            <div className='left'>
                <h1>
                    {t('FirstSection.text1')}
                </h1>
                <div className='Main__FirstSection__buttons'>
                    <div>
                        <Button
                            onClick={sendMoney}
                        >
                            {t('FirstSection.button1')}
                        </Button>
                        <Button
                            onClick={getMoney}
                            bgColor='secondary'
                        >
                            {t('FirstSection.button2')}
                        </Button>
                    </div>
                </div>
            </div>

            <div className='right'>
                <img className='Main__FirstSection__img-1' src={img2}/>
                <img className='Main__FirstSection__img-2' src={img1}/>
            </div>
        </section>
    )
}