import React, {useEffect, useState} from "react";
import './style.css'
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'
import {Button} from "../../../../ui/Button";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {dealReducerActions, dealReducerSelectors} from "../../../../../store/reducers/deal";

export const SuccessStatus = () => {
    const {refill_hash, ...deal} = useSelector(dealReducerSelectors.getDeal)
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [copyAlertAnimation, setCopyAlertAnimation] = useState(false)

    /* methods */
    const goBack = () => {
        history.push('/')
    }

    const copyCode = () => {
        // window.navigator.clipboard.writeText(
        //     refill_hash
        //         ? String(refill_hash)
        //         : ''
        // ).catch(e => {
        //     alert('Your browser does not support coping')
        // })

        let node = document.querySelector('.SetAndGet__transfer-code__code') as HTMLInputElement
        node?.focus();
        node?.select();
        node?.blur();

        try {
            document.execCommand('copy');
        } catch (err) {
            alert('Your browser does not support coping')
        }

        setCopyAlertAnimation(true)
    }
    const disableAlertAnimation = () => {
        setCopyAlertAnimation(false)
    }

    /* clear code */
    useEffect(() => {
        return () => {
            dispatch(dealReducerActions.setDeal({
                data: {
                    ...deal,
                    refill_hash: ''
                }
            }))
        }
    }, [])

    return (
        <div className='Status Status--success'>
            <h4>
                {t('SendAndGet.sendMoney.success.title')}
            </h4>
            <div className='Status__Icon SetAndGet__stage-icon--success'/>

            {refill_hash && (
                <div className='SetAndGet__transfer-code'>
                    <p>{t('SendAndGet.sendMoney.success.refillHash')}</p>
                    <input
                        value={String(refill_hash)}
                        className='SetAndGet__transfer-code__code'
                    />

                    <Button bgColor='secondary' onClick={copyCode}>
                        <span>
                            {t('SendAndGet.sendMoney.success.copy', 'Копировать код')}
                        </span>
                        <div
                            className={cn('SetAndGet__transfer-code__copy', {
                                'SetAndGet__transfer-code__copy--copied': copyAlertAnimation
                            })}
                        >
                            <div onAnimationEnd={disableAlertAnimation}>
                                {t('SendAndGet.sendMoney.success.copyHash', 'Скопировано')}
                            </div>
                        </div>
                    </Button>

                </div>
            )}

            <Button
                onClick={goBack}
            >
                {t('SendAndGet.sendMoney.success.button')}
            </Button>
        </div>
    )
}