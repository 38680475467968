import React, {FC} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import {StagesController} from "./Stages/StagesController";
import {useTranslation} from "react-i18next";


export const SendAndGet: FC = () => {

    const {t} = useTranslation()


    return (
        <div id='sec-1' className='Main__SendAndGet Grid'>
            <div className='left'>
                <div className='FormSection__content-1'>
                    <Title>{t('SendAndGet.header')}</Title>
                </div>
                <div className='FormSection__content-2'>
                    <h3>{t('Commission.header')}</h3>
                    <p>
                        {t('Commission.text1')}<br/>
                        <span>
                            {t(
                                'Commission.text2',
                                'На внутренние переводы между картами Российских банков'
                            )}
                        </span>
                    </p>
                </div>
            </div>

            <div className='right'>
                <div className='Main__SendAndGet__circle'/>
                <StagesController/>
            </div>
        </div>
    )
}
