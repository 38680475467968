import React from 'react'
import './style.css'
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Button} from "../../../../ui/Button";


export const RejectStatus = () => {
    const {t} = useTranslation()
    const history = useHistory()

    const goBackHandler = () => {
        history.push('/deal/start/sendMoney/enterData')
    }

    return (
        <div className='Status Status--reject'>
            <h4 className='SetAndGet__stage-title'>
                {t("SendAndGet.reject.title")}
            </h4>

            <div className='Status__Icon SetAndGet__stage-icon--reject'/>

            <div className='SetAndGet__stage-status-support'>
                {t("SendAndGet.reject.support")}
            </div>

            <Button variant="warning" onClick={goBackHandler}>
                {t("SendAndGet.reject.button")}
            </Button>
        </div>
    )
}