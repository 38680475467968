import React from "react";
import './style.css'

export const Loading = () => {

    return (
        <div className='Loading'>
            <div/>
            <div/>
            <div/>
        </div>
    )
}