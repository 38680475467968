import React, {FC, useState} from 'react'
import './style.css'
import {Input} from "../../../../../ui/Input";
import {hooks} from "../../../../../ui/hooks";
import {useErrors} from "../../../../../../api/methods/useErrors";
import {useSetters} from "../../../../../../api/methods/useSetters";
import {TextArea} from "../../../../../ui/TextArea";
import {Button} from "../../../../../ui/Button";
import {Title} from "../../../../ui/Title";
import {useTranslation} from "react-i18next";
import logo from "../../../../Footer/logo.png";

export const Feedback:FC = () => {
    const [getters, setters] = useSetters()
    const [message, setMessage] = useState('')
    const {t} = useTranslation()

    const form = {
        email: hooks.useInput(),
        phone: hooks.useInput(),
        name: hooks.useInput(),


        errors: useErrors({
            Fields: {
                email: '',
                phone: '',
                name: ''
            },
            Errors: getters.errors
        })
    }

    return (
        <section id='sec-3' className='Feedback'>
            <form className='Feedback__form left'>
                <h4>{t('Contacts.title1')}</h4>
                <Input
                    placeholder='Email'
                    {...form.email}
                    error={form.errors.email}
                />
                <Input
                    placeholder={t('Contacts.input2')}
                    error={form.errors.phone}
                    {...form.phone}
                />
                <Input
                    placeholder={t('Contacts.input3')}
                    error={form.errors.name}
                    {...form.name}
                />
                <TextArea
                    placeholder={t('Contacts.input4')}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                />
                <Button>
                    {t('Contacts.button')}
                </Button>
            </form>
            <div className='right'>
                <Title>
                    {t('Contacts.header')}
                </Title>

                <div className='Feedback__contacts-wrapper'>
                    <img className='Feedback__logo' src={logo}/>
                    <div className='Feedback__contacts'>
                        <h4>KIPARIS DMCC</h4>
                        <p>
                            Unit no. 3O-01-BA322, Jewellery & Gemplex 3
                            Plot No: DMCC – PH2- J&GPlexS
                            Jewellery & Gemplex, Jumeirah Lakes Towers
                            Dubai, United Arab Emirates
                        </p>
                        <a href='mailto:info@kiparisdmcc.ae'>info@kiparisdmcc.ae</a>
                    </div>
                </div>
            </div>
        </section>
    )
}