import React from 'react'
import './style.css'
import {Loading} from "../Loading";
import {useTranslation} from "react-i18next";


export const LoadingStage = () => {
    const {t} = useTranslation()

    return (
        <div className='LoadingStage'>
            <div className='LoadingStage__text'>{t('SendAndGet.loading')}</div>
            <Loading/>
        </div>
    )
}