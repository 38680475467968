import React, {useState} from "react";



type ReturnedValueType = {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    clear: () => void
}

type PropsType = {
    initialValue: string,
    callback?(value: string): void,
    maxNum?: number
}

const initialProps = {
    initialValue: '',
    callback: () => null,
}

export type UseInputReturnedValueType = ReturnedValueType

export const useInput = (props: PropsType = initialProps): ReturnedValueType => {

    const {initialValue, callback = () => null, maxNum} = props;

    /* state */
    const [value, setValue] = useState<string>(initialValue);

    /* methods */
    const clear = () => {
        setValue('')
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (maxNum && (Number(event.target.value) > maxNum)) {
            return
        }
        setValue(event.target.value)
        callback(event.target.value)
    }

    return {
        value,
        onChange: handleChange,
        clear
    }
}
